import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';

import PersonIcon from '@mui/icons-material/Person';
import InventoryIcon from '@mui/icons-material/Inventory';
import CatchingPokemonTwoToneIcon from '@mui/icons-material/CatchingPokemonTwoTone';
import Logout from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../../assets/images/porareLogo.png';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import UserService from '../../services/UserService';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import './Header.css';
import Pokecoin from '../../assets/images/pokecoin.png';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const pages = [
  { title: 'Shop', link: '/shop' },
  { title: 'Catalog', link: '/catalog' },
  { title: 'Battle', link: '/battle' },
  { title: 'Marketplace', link: '/marketplace' }
];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [isLogged, setIsLogged] = useState(UserService.isLoggedIn());
  const [user, setUser] = useState(UserService.getUserFromStorage());
  const [userAvatar, setUserAvatar] = useState('');
  const navigate = useNavigate();
  const [error, setError] = React.useState('');
  const [openPopup, setOpen] = React.useState(false);
  const [dailyClaimed, setDailyClaimed] = useState(false);
  const [isClaimed, setIsClaimed] = useState(false);
  const [toPopupOpen, setToPopupOpen] = useState(false);
  const [rewardPopup, setRewardPopupOpening] = useState(false);
  const [content, setContent] = useState();
  const [contentMessage, setContentMessage] = useState([]);
  const [isPack, setIsPack] = useState(false);
  const [coins, setCoins] = useState(0);

  window.addEventListener('storage', async () => {
    setUser(UserService.getUserFromStorage());
  });

  window.addEventListener('updateCoins', () => {
    getCoins();
  });

  useEffect(() => {
    window.addEventListener('storage', async () => {
      setIsLogged(UserService.isLoggedIn());
      setUser(UserService.getUserFromStorage());

      if (await user) {
        setUserAvatar(user.avatar);
        // setCoins(user.coins);
        setReward();
      }
    });
    // Don't correct the fact that it is void, it's accurate
  }, []);

  useEffect(() => {
    setUser(UserService.getUserFromStorage());

    if (user) {
      setUserAvatar(user.avatar);
      getCoins();

      setReward();
    }
  }, [isLogged]);

  useEffect(() => {
    if (isClaimed) {
      const blackLine = document.querySelector('.black-line');
      const ball = document.querySelector('.main-container');
      const top = document.querySelector('.pokemon-top-part');
      const bottom = document.querySelector('.pokemon-bottom-part');

      // Make ball display block
      ball.style.display = 'block';
      // Make the ball appear smoothly with a transition of 0.5s
      ball.classList.add('ball-appear');

      setTimeout(() => {
        ball.classList.add('shake');
      }, 740);
      let i = 0;

      // Set class to blackLine (.red_circle) using tl for 0.3 s then after 0.3 s remove class
      const blink = setInterval(() => {
        i += 1;
        if (blackLine.classList.contains('red-circle')) {
          blackLine.classList.remove('red-circle');
          if (i > 5) {
            ball.classList.remove('shake');
            clearInterval(blink);
            i = 0;
            toggle();
          }
          return;
        }
        blackLine.classList.add('red-circle');
      }, 300);

      function toggle() {
        // Make top go up
        // Transition for all of it is 0.5 s

        // Make top go up and disappear with a transition of 0.5s
        top.classList.add('top-up');
        // Make bottom go down and disappear with a transition of 0.5s
        bottom.classList.add('bottom-down');

        setToPopupOpen(true);

        setTimeout(() => {
          ball.style.display = 'none';
        }, 1000);
        // Make ball disappear with a transition of 0.5s
      }
    }
  }, [isClaimed]);

  useEffect(() => {
    if (toPopupOpen) {
      setTimeout(() => {
        setRewardPopupOpening(true);
        if (Number.isInteger(content)) {
          const contentToSend = [`You just received ${content}x`, Pokecoin, '15%'];
          setContentMessage(contentToSend);
          window.dispatchEvent(new Event('updateCoins'));
        } else {
          setIsPack(true);
          const contentToSend = [`You just received ${content[0]}`, content[1], '40%'];
          setContentMessage(contentToSend);
        }
      }, 1000);
    }
  }, [content, toPopupOpen]);

  const getCoins = async () => {
    const coins = await UserService.updateCoins();
    setCoins(coins);
  };
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const disconnectUser = () => {
    UserService.logout();
    setAnchorElUser(null);
    setIsLogged(false);
    navigate('/');
  };

  const handleOpenProfile = () => {
    setAnchorElUser(null);
    navigate('/profile');
  };

  const handleClosePopup = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const claimReward = () => {
    setDailyClaimed(true);

    axios({
      method: 'get',
      url: process.env.REACT_APP_API_URL + '/get_reward',
      headers: UserService.axiosToken()
    })
      .then((response) => {
        if (response.data.message['message'] === 'Reward Collected') {
          setIsClaimed(true);
          setContent(response.data.message['content']['reward']);
        }
      })
      .catch((error) => {
        setError(error.response.data.message);
        setOpen(true);
      });
  };

  const setReward = () => {
    setDailyClaimed(false);
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_URL + '/set_reward',
      headers: UserService.axiosToken()
    }).catch(async (error) => {
      if ((await error.response.data.message) === 'Has been collected') {
        setDailyClaimed(true);
      }
    });
  };

  const handleMyPacks = () => {
    setAnchorElUser(null);
    navigate('/myPacks');
  };

  return (
    <AppBar position="fixed" sx={{ bgcolor: 'black', zIndex: '2', height: '4.4em' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box component="img" src={Logo} sx={{ display: { xs: 'none', md: 'flex' }, mr: 1, width: '50px' }} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none'
            }}
          >
            PORARE
          </Typography>
          <Snackbar
            open={openPopup}
            autoHideDuration={6000}
            onClose={handleClosePopup}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          >
            <Alert onClose={handleClosePopup} severity="error" sx={{ width: '100%', marginTop: '120px' }}>
              {error}
            </Alert>
          </Snackbar>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' }
              }}
            >
              {pages.map((page, index) => (
                <MenuItem onClick={handleCloseNavMenu} key={index}>
                  <Link to={page.link} sx={{ textDecoration: 'none' }}>
                    <Typography textAlign="center">{page.title}</Typography>
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box component="img" src={Logo} sx={{ display: { xs: 'flex', md: 'none' }, mr: 1, width: '50px' }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none'
            }}
          >
            PORARE
          </Typography>

          {isLogged ? (
            <>
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: 'none', md: 'flex' },
                  justifyContent: 'flex-end',
                  marginRight: 2
                }}
              >
                {pages.map((page) => (
                  <Button
                    key={page.title}
                    onClick={handleCloseNavMenu}
                    sx={{ my: 2, color: 'white', display: 'block' }}
                  >
                    <Link to={page.link} style={{ textDecoration: 'none', color: 'white' }}>
                      <Typography textAlign="center">{page.title}</Typography>
                    </Link>
                  </Button>
                ))}
                <IconButton
                  sx={{
                    mx: 1,
                    color: '#ffffff',
                    display: 'block',
                    width: '70px',
                    '&.Mui-disabled': {
                      color: '#454545'
                    }
                  }}
                  size="small"
                  aria-label="Claim Daily Reward"
                  aria-controls="menu-appbar"
                  onClick={claimReward}
                  color="inherit"
                  disabled={dailyClaimed}
                >
                  <CatchingPokemonTwoToneIcon />
                </IconButton>
                <Box
                  sx={{
                    width: '10%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'row'
                  }}
                >
                  <Typography
                    sx={{
                      color: 'white',
                      fontSize: '1.1rem',
                      fontWeight: 700,
                      marginRight: '5px'
                    }}
                  >
                    {/*  Coins */}
                    {coins}
                  </Typography>
                  <Box component={'img'} src={Pokecoin} sx={{ width: '35px' }} />
                </Box>
              </Box>
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt="User 1" src={userAvatar} />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem onClick={handleOpenProfile}>
                    <ListItemIcon>
                      <PersonIcon fontSize="small" />
                    </ListItemIcon>
                    Profile
                  </MenuItem>
                  <MenuItem onClick={handleMyPacks}>
                    <ListItemIcon>
                      <InventoryIcon fontSize="small" />
                    </ListItemIcon>
                    My Packs
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={disconnectUser}>
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </Box>
            </>
          ) : (
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }}>
              <Button to="/register" component={Link} variant="body2">
                Sign Up
              </Button>
              <Button to="/login" component={Link} variant="body2" style={{ backgroundColor: '#3f50b5' }}>
                Sign In
              </Button>
            </Box>
          )}
        </Toolbar>
      </Container>
      <Box className="main-container">
        <Box className="pokemon-top-part"></Box>
        <Box className="main-forms">
          <h2>boosters</h2>
        </Box>
        <Box className="pokemon-bottom-part">
          <Box className="white-part"></Box>
          <Box className="black-line"></Box>
        </Box>
      </Box>

      <Dialog open={rewardPopup} onClose={() => setRewardPopupOpening(false)}>
        <DialogTitle>You have claimed your daily reward !</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: isPack ? 'column' : 'row',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              marginTop: 4,
              marginBottom: 3
            }}
          >
            <Typography variant="p" sx={{ fontSize: '1.5rem' }}>
              {contentMessage[0]}
            </Typography>

            <Box
              component="img"
              src={contentMessage[1]}
              sx={{
                width: `${contentMessage[2]}`,
                height: 'auto',
                marginLeft: isPack ? 0 : 2,
                marginTop: isPack ? 4 : 0
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRewardPopupOpening(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </AppBar>
  );
}

export default ResponsiveAppBar;
