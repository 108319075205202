import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { styled, useTheme } from '@mui/material/styles';

import SadPikachu from '../../assets/images/sadPikachu.png';

import {
  Box,
  Grid,
  RadioGroup,
  Radio,
  Button,
  Drawer,
  List,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  FormControlLabel,
  FormControl,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  OutlinedInput,
  Typography,
  Modal,
  Alert,
  Snackbar
} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import StarIcon from '@mui/icons-material/Star';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import BloodtypeIcon from '@mui/icons-material/Bloodtype';
import UserService from '../../services/UserService';
import Pokecoin from '../../assets/images/pokecoin.png';

const drawerWidth = 250;

const style = {
  width: 300,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: 5,
  boxShadow: 24,
  p: 4,
  color: 'black',
  textAlign: 'center'
};

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end'
}));

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  marginLeft: 0,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: `${drawerWidth}px`
  })
}));

function Marketplace() {
  const [cards, setCards] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [setIsLoading] = useState(true);
  const [visibleCards, setVisibleCards] = useState(30);

  const [moreCards, setMoreCards] = useState(false);

  const [cardId, setCardId] = useState([]);

  const [openModal, setOpenModal] = React.useState(false);

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const [selectedType, setSelectedType] = useState('');
  const [selectedRarity, setSelectedRarity] = useState('');

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [snackbarSeverity, setSnackbarSeverity] = React.useState('success');

  // Rarities & Types arrays
  const rarities = [
    { title: 'Common' },
    { title: 'Uncommon' },
    { title: 'Rare' },
    { title: 'Rare ACE' },
    { title: 'Rare BREAK' },
    { title: 'Rare Holo' },
    { title: 'Rare Holo EX' },
    { title: 'Rare Holo GX' },
    { title: 'Rare Holo LV.X' },
    { title: 'Rare Holo Star' },
    { title: 'Rare Holo V' },
    { title: 'Rare Holo VMAX' },
    { title: 'Rare Prime' },
    { title: 'Rare Prism Star' },
    { title: 'Rare Rainbow' },
    { title: 'Rare Secret' },
    { title: 'Rare Shining' },
    { title: 'Rare Shiny' },
    { title: 'Rare Shiny GX' },
    { title: 'Rare Ultra' },
    { title: 'Amazing Rare' },
    { title: 'LEGEND' },
    { title: 'Promo' }
  ];

  const types = [
    { title: 'Colorless' },
    { title: 'Darkness' },
    { title: 'Dragon' },
    { title: 'Fairy' },
    { title: 'Fighting' },
    { title: 'Fire' },
    { title: 'Grass' },
    { title: 'Lightning' },
    { title: 'Metal' },
    { title: 'Psychic' },
    { title: 'Water' }
  ];

  if (!UserService.isLoggedIn()) {
    window.location.href = '/login';
  }

  // Modal functions
  const handleOpenModal = (card) => {
    setCardId(card);
    setOpenModal(true);
  };

  const handleCloseModal = () => setOpenModal(false);

  // Drawer functions
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // Snackbar functions
  const handleOpenSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  // Fetch all cards
  async function FetchCards() {
    let allCards = [];
    await axios
      .get(process.env.REACT_APP_API_URL + '/marketplace/all', {
        headers: UserService.axiosToken()
      })
      .then((response) => {
        allCards = response.data.message.data;
        setCards(allCards);
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(false);
  }

  const buyPack = async (id) => {
    await axios
      .get(process.env.REACT_APP_API_URL + '/marketplace/buy/' + id.id, {
        headers: UserService.axiosToken()
      })
      .then((response) => {
        handleOpenSnackbar(response.data.message, 'success');
        handleCloseModal();
        FetchCards();
        window.dispatchEvent(new Event('updateCoins'));
      })
      .catch((error) => {
        handleOpenSnackbar(
          error.response.data.message ||
            'Something went wrong, the card has already been bought? Refresh the page and try again.',
          'error'
        );
      });
  };

  useEffect(() => {
    FetchCards();
  }, []);

  // Filters functions
  const handleNameChange = (e) => {
    setFilterName(e.target.value);
  };

  const handleRareChange = (event) => {
    setSelectedRarity(event.target.value);
  };

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  // Cards filter
  const filteredCards = cards.filter((card) => {
    const typesIncluded = selectedType === '' || card?.pokemon?.types?.includes(selectedType);
    const raritiesIncluded = selectedRarity === '' || card?.pokemon?.rarity?.includes(selectedRarity);

    if (!typesIncluded) {
      return false;
    }

    if (!raritiesIncluded) {
      return false;
    }

    if (filterName && card.pokemon.name.toLowerCase().indexOf(filterName.toLowerCase()) === -1) {
      return false;
    }

    return true;
  });

  // Show more cards
  const handleShowMore = () => {
    setVisibleCards(visibleCards + 30); // augmenter le nombre d'éléments à afficher
  };

  useEffect(() => {
    if (filteredCards.length > visibleCards) {
      setMoreCards(true);
    } else {
      setMoreCards(false);
    }
  }, [visibleCards, filteredCards]);

  let niveauDeScroll = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

  return (
    <>
      <Snackbar
        sx={{
          top: niveauDeScroll,
          height: '190%',
          '& .MuiSnackbar-anchorOriginBottomLeft': {
            position: 'absolute'
          },
          bottom: 0,
          left: 0
        }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          color={snackbarSeverity}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Button
        color="inherit"
        variant="outlined"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        sx={{ ...(open ? { display: 'none' } : { display: 'flex' }), mr: 2, ml: 2, mt: 2 }}
      >
        <FilterAltIcon sx={{ pr: '10px' }} /> Open filters
      </Button>
      <Box sx={{ zIndex: '1', position: 'relative' }}>
        <Drawer
          sx={{
            width: drawerWidth,
            zIndex: '1',
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box'
            }
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </DrawerHeader>

          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
            <List>
              <ListItem sx={{ justifyContent: 'flex-end' }} disablePadding onClick={handleDrawerClose}>
                <IconButton>
                  <IconButton>{theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />} </IconButton>
                </IconButton>
              </ListItem>

              {/* Filter by Name */}
              <Accordion>
                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                  <ListItem disablePadding>
                    <ListItemButton>
                      <ListItemIcon>
                        <SearchIcon />{' '}
                      </ListItemIcon>
                      <ListItemText primary={'Filter by Name'} />
                    </ListItemButton>
                  </ListItem>
                </AccordionSummary>

                <AccordionDetails>
                  <OutlinedInput type="text" placeholder="Enter name" value={filterName} onChange={handleNameChange} />
                </AccordionDetails>
              </Accordion>

              {/* Filter by Rarity */}
              <Accordion>
                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                  <ListItem disablePadding>
                    <ListItemButton>
                      <ListItemIcon>
                        <StarIcon />{' '}
                      </ListItemIcon>
                      <ListItemText primary={'Filter by Rarity'} />
                    </ListItemButton>
                  </ListItem>
                </AccordionSummary>

                <AccordionDetails>
                  <FormControl component="fieldset">
                    <RadioGroup value={selectedRarity} onChange={handleRareChange}>
                      {rarities.map((rarity) => (
                        <FormControlLabel
                          key={rarity.title}
                          value={rarity.title}
                          control={<Radio />}
                          label={rarity.title}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </AccordionDetails>
              </Accordion>

              {/* Filter by Type */}
              <Accordion>
                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                  <ListItem disablePadding>
                    <ListItemButton>
                      <ListItemIcon>
                        <BloodtypeIcon />{' '}
                      </ListItemIcon>
                      <ListItemText primary={'Filter by Type'} />
                    </ListItemButton>
                  </ListItem>
                </AccordionSummary>

                <AccordionDetails>
                  <FormControl component="fieldset">
                    <RadioGroup value={selectedType} onChange={handleTypeChange}>
                      {types.map((type) => (
                        <FormControlLabel key={type.title} value={type.title} control={<Radio />} label={type.title} />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </AccordionDetails>
              </Accordion>
            </List>

            <Button
              variant="outlined"
              color="inherit"
              onClick={() => {
                setSelectedType('');
                setSelectedRarity('');
                setFilterName('');

                setFilterName('');
              }}
              sx={{ m: '1rem' }}
            >
              Reset filters
            </Button>
          </Box>
        </Drawer>
      </Box>
      <Main open={open}>
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          {filteredCards?.length ? (
            <>
              {filteredCards?.slice(0, visibleCards)?.map((card) => {
                return (
                  <Grid item xs={2} sm={4} md={4}>
                    <Grid>
                      <Button onClick={() => handleOpenModal(card)}>
                        <img src={card?.pokemon.images.small} alt="img" />
                      </Button>
                      <Typography variant="body1">
                        {card?.pokemon.name} for {card?.price}
                        <Box
                          component={'img'}
                          src={Pokecoin}
                          sx={{ width: '30px', ml: 0.5, transform: 'translateY(30%)' }}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
              <Box sx={{ textAlign: 'center', width: '100%', mt: 5, display: !moreCards && 'none' }}>
                <Button variant="outlined" color="inherit" onClick={handleShowMore}>
                  Voir plus
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 600,
                  width: '100%'
                }}
              >
                <Box component="img" src={SadPikachu} sx={{ display: { xs: 'none', md: 'flex' }, width: '30%' }} />
                <Typography variant="body1" sx={{ fontSize: '2rem', ml: 4 }}>
                  No cards on marketplace
                </Typography>
              </Box>
            </>
          )}
        </Grid>

        {/* <button onClick={handleShowMore}>Voir plus</button> */}
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: niveauDeScroll,
            height: '100%',
            '& .MuiModal-backdrop': {
              position: 'absolute'
            }
          }}
        >
          <Box sx={style}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
              <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold' }}>
                {cardId?.pokemon?.name}
              </Typography>
              <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold' }}>
                {cardId?.pokemon?.supertype === 'Pokémon'
                  ? `N°${cardId?.pokemon?.nationalPokedexNumbers}`
                  : cardId?.pokemon?.supertype}
              </Typography>
            </Box>

            <Box
              component="img"
              src={cardId?.pokemon?.images?.small}
              sx={{ display: 'flex', m: 'auto', p: '1rem 0' }}
            />
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {cardId?.seller} want to sell {cardId?.pokemon?.name} for {cardId?.price}
              <Box component={'img'} src={Pokecoin} sx={{ width: '35px', ml: 0.5, transform: 'translateY(30%)' }} /> !
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', mt: '1rem' }}>
              <Button variant="outlined" color="error" onClick={handleCloseModal}>
                Close
              </Button>
              <Button
                variant="outlined"
                color="info"
                onClick={() => {
                  buyPack(cardId);
                }}
              >
                Buy
              </Button>
            </Box>
          </Box>
        </Modal>
      </Main>
    </>
  );
}

export default Marketplace;
