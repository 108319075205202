import { Box, LinearProgress, linearProgressClasses, Modal, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import UserService from '../../../services/UserService';
import { useNavigate } from 'react-router-dom';

function Fight({ userCards, chosenPokemon, setChosenPokemon, setIsBattle, battleData }) {
  const [userPokemon, setUserPokemon] = useState([]);
  const [willAttack, setWillAttack] = useState(false);
  const [round, setRound] = useState(1);
  const [battleDataAdversaryPokemonHp, setBattleDataAdversaryPokemonHp] = useState(battleData?.adversary?.hp);
  const [battleDataUserPokemonHp, setBattleDataUserPokemonHp] = useState(battleData?.me?.hp);
  const [errorMessages, setErrorMessages] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  const [attackMessage, setAttackMessage] = useState('');
  const [opponentAction, setOpponentAction] = useState('');
  const [passMessage, setPassMessage] = useState('');
  const [willHeal, setWillHeal] = useState(false);
  const [healMessage, setHealMessage] = useState(null);
  const [openConclusion, setOpenConclusion] = useState(false);
  const [isWinner, setIsWinner] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const [willRun, setWillRun] = useState(false);
  const [userEnergy, setUserEnergy] = useState(battleData?.me?.energy);

  const navigate = useNavigate();
  console.log('battleData', battleData);
  const getPokemonById = (id) => {
    const pokemon = userCards.find((pokemon) => pokemon.data.id === id);
    setUserPokemon(pokemon);
  };
  useEffect(() => {
    getPokemonById(chosenPokemon);
  }, []);

  const pokemonPokedexId = userPokemon?.data?.nationalPokedexNumbers;
  const opponentPokemon = battleData?.adversary?.pokemon;
  const opponentPokemonPokedexId = opponentPokemon?.nationalPokedexNumbers;

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 15,
    borderRadius: 10,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'
    }
  }));

  const calculatePercentagePokemonHp = (pokemonHpMax, pokemonHp) => {
    const percentage = pokemonHp / pokemonHpMax;
    return percentage * 100;
  };

  const ActionBloc = () => {
    if (errorMessages) {
      return (
        <Typography variant="h6" sx={{ color: 'red', fontFamily: 'Pokemon', fontSize: 20 }}>
          {errorMessages}
        </Typography>
      );
    }
    if (attackMessage) {
      return (
        <Typography variant="h6" sx={{ color: 'black', fontFamily: 'Pokemon', fontSize: 20 }}>
          {userPokemon?.data?.name}
          {' is attacking with '}
          {attackMessage}
        </Typography>
      );
    }
    if (opponentAction) {
      if (opponentAction?.type === 'pass')
        return (
          <Typography variant="h6" sx={{ color: 'green', fontFamily: 'Pokemon', fontSize: 20 }}>
            {opponentPokemon?.name}
            {' is passing'}
          </Typography>
        );
      if (opponentAction?.type === 'attack')
        return (
          <Typography variant="h6" sx={{ color: 'green', fontFamily: 'Pokemon', fontSize: 20 }}>
            {opponentPokemon?.name}
            {' is attacking with '}
            {opponentAction?.name}
          </Typography>
        );
      if (opponentAction?.type === 'heal')
        return (
          <Typography variant="h6" sx={{ color: 'green', fontFamily: 'Pokemon', fontSize: 20 }}>
            {opponentPokemon?.name}
            {' is healing'}
          </Typography>
        );
      return (
        <Typography variant="h6" sx={{ color: 'green', fontFamily: 'Pokemon', fontSize: 20 }}>
          {opponentPokemon?.name}
          {' is dead'}
        </Typography>
      );
    }
    if (passMessage) {
      return (
        <Typography variant="h6" sx={{ color: 'black', fontFamily: 'Pokemon', fontSize: 20 }}>
          {userPokemon?.data?.name}
          {' is passing'}
        </Typography>
      );
    }
    if (healMessage) {
      return (
        <Typography variant="h6" sx={{ color: 'black', fontFamily: 'Pokemon', fontSize: 20 }}>
          {userPokemon?.data?.name}
          {' is healing '}
          {healMessage}
        </Typography>
      );
    }
    return (
      <>
        <Typography variant="h6" sx={{ color: 'black', fontFamily: 'Pokemon', fontSize: 20 }}>
          What will
        </Typography>
        <Typography variant="h6" sx={{ color: 'black', fontFamily: 'Pokemon', fontSize: 20 }}>
          {userPokemon?.data?.name} do ?
        </Typography>
      </>
    );
  };

  const ActionButton = () => {
    if (willAttack) {
      return (
        <>
          {userPokemon?.data?.attacks
            ?.filter((attack) => attack?.damage !== '')
            ?.map((attack) => {
              return (
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    disabled={disableButton}
                    sx={{
                      width: '100%',
                      height: 50,
                      fontSize: 30,
                      color: 'blue'
                    }}
                    onClick={() => handleAttackOpponent(attack.name)}
                  >
                    <Typography sx={{ marginRight: 1, fontFamily: 'Pokemon', fontSize: 10 }}>{attack.name}</Typography>
                    <Typography
                      sx={{
                        marginRight: 1,
                        fontFamily: 'Pokemon',
                        fontSize: 10
                      }}
                    >
                      Damage {attack.damage}
                    </Typography>
                    <Typography sx={{ fontFamily: 'Pokemon', fontSize: 10 }}>Energy {attack.cost.length}</Typography>
                  </Button>
                </Grid>
              );
            })}
          <Button
            variant="outlined"
            disabled={disableButton}
            xs={6}
            sx={{
              width: 200,
              height: 50,
              fontSize: 20,
              color: 'blue',
              marginTop: 1,
              fontFamily: 'Pokemon'
            }}
            onClick={() => setWillAttack(false)}
          >
            back
          </Button>
        </>
      );
    }
    if (willHeal) {
      return (
        <>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              disabled={disableButton}
              sx={{
                width: '100%',
                height: 50,
                fontSize: 30,
                color: 'blue'
              }}
              onClick={() => handleHealPokemon(20)}
            >
              <Typography sx={{ marginRight: 1, fontFamily: 'Pokemon', fontSize: 10 }}>Heal 20 HP</Typography>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              disabled={disableButton}
              sx={{
                width: '100%',
                height: 50,
                fontSize: 30,
                color: 'blue'
              }}
              onClick={() => handleHealPokemon(50)}
            >
              <Typography sx={{ marginRight: 1, fontFamily: 'Pokemon', fontSize: 10 }}>Heal 50 HP</Typography>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              disabled={disableButton}
              sx={{
                width: '100%',
                height: 50,
                fontSize: 30,
                color: 'blue'
              }}
              onClick={() => handleHealPokemon(120)}
            >
              <Typography sx={{ marginRight: 1, fontFamily: 'Pokemon', fontSize: 10 }}>Heal 120 HP</Typography>
            </Button>
          </Grid>
          <Button
            variant="outlined"
            disabled={disableButton}
            xs={6}
            sx={{
              width: 200,
              height: 50,
              fontSize: 20,
              color: 'blue',
              marginTop: 1,
              fontFamily: 'Pokemon'
            }}
            onClick={() => setWillHeal(false)}
          >
            back
          </Button>
        </>
      );
    }
    if (willRun) {
      return (
        <>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              disabled={disableButton}
              sx={{
                width: '100%',
                height: 50,
                fontSize: 30,
                color: 'blue'
              }}
              onClick={() => handleRun()}
            >
              <Typography sx={{ marginRight: 1, fontFamily: 'Pokemon', fontSize: 10 }}>Yes i want to Run</Typography>
            </Button>
          </Grid>
          <Button
            variant="outlined"
            disabled={disableButton}
            xs={6}
            sx={{
              width: 200,
              height: 50,
              fontSize: 20,
              color: 'blue',
              marginTop: 1,
              fontFamily: 'Pokemon'
            }}
            onClick={() => setWillRun(false)}
          >
            back
          </Button>
        </>
      );
    }
    return (
      <>
        {/* Attack button */}
        <Grid item xs={6}>
          <Button
            variant="outlined"
            disabled={disableButton}
            sx={{
              width: 200,
              height: 100,
              fontSize: 20,
              color: 'blue',
              fontFamily: 'Pokemon'
            }}
            onClick={() => setWillAttack(true)}
          >
            attack
          </Button>
        </Grid>
        {/* Run button */}
        <Grid item xs={6}>
          <Button
            variant="outlined"
            disabled={disableButton}
            xs={6}
            sx={{
              width: 200,
              height: 100,
              fontSize: 20,
              color: 'blue',
              fontFamily: 'Pokemon'
            }}
            onClick={() => setWillRun(true)}
          >
            run
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="outlined"
            disabled={disableButton}
            xs={6}
            sx={{
              width: 200,
              height: 100,
              fontSize: 20,
              color: 'blue',
              fontFamily: 'Pokemon'
            }}
            onClick={() => setWillHeal(true)}
          >
            Heal
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="outlined"
            disabled={disableButton}
            xs={6}
            sx={{
              width: 200,
              height: 100,
              fontSize: 20,
              color: 'blue',
              fontFamily: 'Pokemon'
            }}
            onClick={() => handlePass()}
          >
            Pass
          </Button>
        </Grid>
      </>
    );
  };

  const handleAttackOpponent = (attakName) => {
    setDisableButton(true);
    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_URL + `/battle/action/${battleData.game.battle}`,
      headers: UserService.axiosToken(),
      data: {
        action: 'attack',
        name: attakName
      }
    })
      .then((response) => {
        console.log(response.data);
        console.log('response', response);
        setRound(response.data.message.game.round);
        setBattleDataAdversaryPokemonHp(response.data.message.adversary.hp);
        setBattleDataUserPokemonHp(response.data.message.me.hp);
        setAttackMessage(attakName);
        setUserEnergy(response.data.message.me.energy);
        setTimeout(() => {
          setAttackMessage('');
          setOpponentAction(response?.data?.message?.adversary?.action);
          setTimeout(() => {
            setOpponentAction('');
            setDisableButton(false);
            setWillAttack(false);
            setIsEnd(response.data.message.game.end);
            setIsWinner(response.data.message.game.winner);
          }, 2000);
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        console.log('mon erreur', error.response.data.message);
        setErrorMessages(error.response.data.message);
        setTimeout(() => {
          setErrorMessages('');
          setDisableButton(false);
        }, 2000);
      });
  };

  const handlePass = () => {
    setDisableButton(true);
    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_URL + `/battle/action/${battleData.game.battle}`,
      headers: UserService.axiosToken(),
      data: {
        action: 'pass'
      }
    })
      .then((response) => {
        console.log(response.data);
        setRound(response.data.message.game.round);
        setBattleDataAdversaryPokemonHp(response.data.message.adversary.hp);
        setBattleDataUserPokemonHp(response.data.message.me.hp);
        setPassMessage('pass');
        setUserEnergy(response.data.message.me.energy);
        setTimeout(() => {
          setPassMessage('');
          setOpponentAction(response.data.message.adversary.action);
          setTimeout(() => {
            setOpponentAction('');
            setDisableButton(false);
            setWillAttack(false);
            setIsEnd(response.data.message.game.end);
            setIsWinner(response.data.message.game.winner);
          }, 2000);
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleHealPokemon = (healLevel) => {
    setDisableButton(true);
    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_URL + `/battle/action/${battleData.game.battle}`,
      headers: UserService.axiosToken(),
      data: {
        action: 'heal',
        heal: healLevel
      }
    })
      .then((response) => {
        console.log(response.data);
        setRound(response.data.message.game.round);
        setBattleDataAdversaryPokemonHp(response.data.message.adversary.hp);
        setBattleDataUserPokemonHp(response.data.message.me.hp);
        setHealMessage(healLevel);
        setUserEnergy(response.data.message.me.energy);
        setTimeout(() => {
          setHealMessage(null);
          setOpponentAction(response.data.message.adversary.action);
          setTimeout(() => {
            setOpponentAction('');
            setDisableButton(false);
            setWillHeal(false);
            setIsEnd(response.data.message.game.end);
            setIsWinner(response.data.message.game.winner);
          }, 2000);
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRun = () => {
    setDisableButton(true);
    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_URL + `/battle/action/${battleData.game.battle}`,
      headers: UserService.axiosToken(),
      data: {
        action: 'runaway'
      }
    })
      .then((response) => {
        console.log(response.data);
        setRound(response.data.message.game.round);
        setBattleDataAdversaryPokemonHp(response.data.message.adversary.hp);
        setBattleDataUserPokemonHp(response.data.message.me.hp);
        setIsEnd(response.data.message.game.end);
        setIsWinner(response.data.message.game.winner);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleOpen = () => setOpenConclusion(true);
  const handleClose = () => {
    setOpenConclusion(false);
    setIsBattle(false);
    navigate('/battle');
  };

  const endBattle = () => {
    if (isEnd) {
      handleOpen();
    }
  };
  useEffect(() => {
    endBattle();
  }, [isEnd]);
  return (
    <>
      {/* background */}
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          bgcolor: 'background.paper',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Stack>
          {/* Battle fight background */}
          <Box
            sx={{
              width: 900,
              height: 500,
              marginTop: 2,
              background:
                'url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9xwJh5Gs8fucdGZuGLh_5xZ_bwFEWFhdYNkV1P0LS7J6GtwmcNw)',
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat'
            }}
          >
            {/* Opponent Pokemon */}
            <Box
              component="img"
              src={`https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/versions/generation-v/black-white/animated/${opponentPokemonPokedexId}.gif`}
              alt="Pokemon"
              sx={{
                width: 250,
                height: 200,
                position: 'relative',
                top: 60,
                left: 230
              }}
            />
            {/* Opponent Pokemon HP */}
            <Box
              sx={{
                position: 'relative',
                backgroundColor: 'white',
                display: 'flex',
                width: 400,
                minHeight: 100,
                maxHeight: 150,
                top: -180,
                left: 100,
                borderRadius: 2,
                boxShadow: 1,
                borderColor: 'grey.500',
                borderStyle: 'solid',
                borderWidth: 1,
                flexDirection: 'column',
                justifyContent: 'flex-end'
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', marginX: 5, alignItems: 'center' }}>
                <Typography variant="h4" sx={{ color: 'black', fontFamily: 'Pokemon', fontSize: 20 }}>
                  {battleData.adversary.name}
                  {"'s "}
                  {opponentPokemon?.name}
                </Typography>
                <Typography variant="h4" sx={{ color: 'black', fontFamily: 'Pokemon', fontSize: 20 }}>
                  lvl {opponentPokemon?.level || 23}
                </Typography>
              </Box>

              <Box
                sx={{
                  color: 'black',
                  width: '100%',
                  height: 100,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  maxHeight: 80
                }}
              >
                <Box
                  sx={{
                    width: '70%',
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: 2
                  }}
                >
                  <Typography variant="h5" sx={{ marginRight: 2, fontFamily: 'Pokemon', fontSize: 20 }}>
                    HP
                  </Typography>
                  <Box sx={{ flexGrow: 1 }}>
                    <BorderLinearProgress
                      variant="determinate"
                      value={calculatePercentagePokemonHp(parseInt(opponentPokemon?.hp), battleDataAdversaryPokemonHp)}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            {/* Our Pokemon */}
            <Box
              component="img"
              src={`https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/versions/generation-v/black-white/animated/back/${pokemonPokedexId}.gif`}
              alt="Pokemon"
              sx={{
                width: 300,
                height: 250,
                position: 'relative',
                top: -110,
                left: -250
              }}
            />
            <Box
              sx={{
                position: 'relative',
                backgroundColor: 'white',
                display: 'flex',
                width: 400,
                minHeight: 100,
                maxHeight: 150,
                top: -280,
                left: 450,
                borderRadius: 2,
                boxShadow: 1,
                borderColor: 'grey.500',
                borderStyle: 'solid',
                borderWidth: 1,
                flexDirection: 'column',
                justifyContent: 'flex-end'
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', marginX: 5 }}>
                <Typography variant="h4" sx={{ color: 'black', fontFamily: 'Pokemon', fontSize: 20 }}>
                  {userPokemon?.data?.name}
                </Typography>
                <Typography variant="h4" sx={{ color: 'black', fontFamily: 'Pokemon', fontSize: 20 }}>
                  lvl {userPokemon?.data?.level || 26}
                </Typography>
              </Box>

              <Box
                sx={{
                  color: 'black',
                  width: '100%',
                  height: 100,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  maxHeight: 80
                }}
              >
                <Box
                  sx={{
                    width: '70%',
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: 2
                  }}
                >
                  <Typography variant="h5" sx={{ marginRight: 2, fontFamily: 'Pokemon', fontSize: 20 }}>
                    HP
                  </Typography>
                  <Box sx={{ flexGrow: 1 }}>
                    <BorderLinearProgress
                      variant="determinate"
                      value={calculatePercentagePokemonHp(parseInt(userPokemon?.data?.hp), battleDataUserPokemonHp)}
                    />
                  </Box>
                </Box>
                <Typography variant="h5" sx={{ marginRight: 2 }}>
                  {' '}
                  E : {userEnergy}
                </Typography>
              </Box>
            </Box>
          </Box>

          {/* Under fight */}
          <Stack
            direction="row"
            spacing={2}
            sx={{
              marginTop: 6,
              width: 900
            }}
          >
            <Stack
              sx={{
                width: '50%',
                alignItems: 'center',
                justifyContent: 'center',
                border: 1,
                borderColor: 'blue',
                marginTop: 1
              }}
            >
              <Box>
                <ActionBloc />
              </Box>
            </Stack>

            {/* Action button */}
            <Grid container sx={{ width: '50%' }} rowSpacing={1}>
              <ActionButton />
            </Grid>
          </Stack>
        </Stack>
        <Modal
          open={openConclusion}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'black' }}
        >
          <Box sx={{ backgroundColor: 'white', padding: 5 }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {isWinner ? 'You win' : 'You lose'}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {isWinner
                ? `You have defeated ${battleData.adversary.name}'s ${opponentPokemon?.name}`
                : `You have been defeated by ${battleData.adversary.name}'s ${opponentPokemon?.name} `}
            </Typography>
          </Box>
        </Modal>
      </Box>
    </>
  );
}

export default Fight;
