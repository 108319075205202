import './App.css';
import RoutesProvider from './routes';
import Header from './components/Header/Header';
import { Box } from '@mui/material';
import { createGlobalStyle } from 'styled-components';
import { useLocation } from 'react-router-dom';

const GlobalStyle = createGlobalStyle`
  body {
    height: 100vh;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 29, 61, 1) 10%, rgba(0, 23, 49, 1) 80%, rgba(0, 0, 0, 1) 100%);
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
  }
`;

function App() {
  const location = useLocation();
  const pathname = location.pathname;
  return (
    <>
      <GlobalStyle />
      {pathname !== '/battle' ? (
        <>
          <Header />
          <Box sx={{ paddingTop: 8.8 }}>
            <RoutesProvider />
          </Box>
        </>
      ) : (
        <RoutesProvider />
      )}
    </>
  );
}

export default App;
