import React, { useEffect, useState } from 'react';
import './Evolve.css';

import { Box, Typography } from '@mui/material';

export default function Evolve(pokemonsPokedexId) {
  const [showText, setShowText] = useState(false);

  let canEvolve = true;

  function evolve() {
    if (!canEvolve) {
      return;
    }

    let main = document.querySelector('.main');
    let mainCurrentEvolution = main.querySelector('.current');
    let nextStage = mainCurrentEvolution.nextElementSibling;

    let circles = [...document.querySelectorAll('.circle')];
    let bubbles = [...document.querySelectorAll('.bubble')];

    const animationTime = 12;

    canEvolve = false;
    setTimeout(() => {
      canEvolve = true;

      circles.map((el) => (el.style.animation = ''));
      bubbles.map((el) => (el.style.animation = ''));

      document.body.style.background = '';

      mainCurrentEvolution.style.animation = '';
      mainCurrentEvolution.classList.remove('current');

      nextStage.style.animation = '';
      nextStage.classList.add('current');
      setShowText(true);
    }, 500 + animationTime * 1000);

    circles.map((el, i) => (el.style.animation = `tunnel ${animationTime}s linear ${i * 0.1}s`));

    bubbles.map((el, i) => (el.style.animation = `bubble .4s reverse ${animationTime - 2 + i * 0.05}s`));

    mainCurrentEvolution.style.animation = `evolve-out ${animationTime}s forwards`;
    nextStage.style.animation = `evolve-in ${animationTime}s forwards`;
  }

  useEffect(() => {
    evolve();
  }, []);

  return (
    <>
      <Box className="grid">
        <Box className="carousel">
          <Box className="circle c1"></Box>
          <Box className="circle c2"></Box>
          <Box className="circle c3"></Box>
          <Box className="circle c4"></Box>
          <Box className="circle c5"></Box>
          <Box className="bubble-wrap">
            <Box className="bubble"></Box>
            <Box className="bubble"></Box>
            <Box className="bubble"></Box>
            <Box className="bubble"></Box>
            <Box className="bubble"></Box>
            <Box className="bubble"></Box>
            <Box className="bubble"></Box>
            <Box className="bubble"></Box>
            <Box className="bubble"></Box>
            <Box className="bubble"></Box>
            <Box className="bubble"></Box>
            <Box className="bubble"></Box>
            <Box className="bubble"></Box>
            <Box className="bubble"></Box>
            <Box className="bubble"></Box>
            <Box className="bubble"></Box>
            <Box className="bubble"></Box>
            <Box className="bubble"></Box>
            <Box className="bubble"></Box>
            <Box className="bubble"></Box>
          </Box>
          <Box className="carousel-item main">
            <Box className="evolve">
              <img
                class="pokemon stage-1 current"
                alt="pokemon"
                src={`https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/versions/generation-v/black-white/${pokemonsPokedexId?.pokemonsPokedexId?.pokemon[0]}.png`}
              />
              <img
                class="pokemon stage-2"
                alt="evolution"
                src={`https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/versions/generation-v/black-white/${pokemonsPokedexId?.pokemonsPokedexId?.evolution[0]}.png`}
              />
            </Box>
            <Box sx={{ mr: '27%' }}>
              {showText && (
                <Box
                  width="100%"
                  sx={{
                    border: '1px solid white',
                    borderRadius: '20px',
                    p: 3
                  }}
                >
                  <Typography variant="p" sx={{ width: '100%', color: 'white' }}>
                    Your {pokemonsPokedexId?.pokemonsPokedexId?.pokemon[1]} evolve to{' '}
                    {pokemonsPokedexId?.pokemonsPokedexId?.evolution[1]} !
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
