import {
  Avatar,
  Box,
  Stack,
  Typography,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Alert,
  Snackbar,
  OutlinedInput,
  CircularProgress,
  FormControl,
  FormLabel,
  DialogTitle,
  DialogContentText,
  DialogActions
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import React, { useEffect, useState } from 'react';
import UserService from '../../services/UserService';
import axios from 'axios';
import Evolve from './evolve/Evolve';
import Loading from '../../components/Loading/Loading';
import {
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  Button,
  Dialog,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  Input,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Chip,
  DialogContent
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import Pokecoin from '../../assets/images/pokecoin.png';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Profile() {
  const [user] = useState(UserService.getUserFromStorage());
  const [userAvatar, setUserAvatar] = useState('');
  const [userCards, setUserCards] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [dialogCard, setDialogCard] = useState();
  const [price, setPrice] = useState('');
  const [choice, setChoice] = useState(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [snackbarSeverity, setSnackbarSeverity] = React.useState('success');
  const [evolvePrice, setEvolvePrice] = useState(0);
  const [isPriceLoaded, setIsPriceLoaded] = useState(false);
  const [pokemonsPokedexId, setPokemonsPokedexId] = useState([]);
  const [openEvolveAnimation, setOpenEvolveAnimation] = useState(false);
  const [filterType, setFilterType] = useState('price-desc');
  const [openFilter, setOpenFilter] = useState(false);
  const [healPrice, setHealPrice] = useState(0);
  const [isHealPriceLoaded, setIsHealPriceLoaded] = useState(false);
  const [openHeal, setOpenHeal] = useState(false);

  const [profValue, setProfValue] = useState(0);
  const [marketValue, setMarketValue] = useState(0);

  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingHealingButton, setLoadingHealingButton] = useState(false);

  const [filterName, setFilterName] = useState('');

  if (!UserService.isLoggedIn()) {
    window.location.href = '/login';
  }
  const handleClickOpen = (card) => {
    setOpen(true);
    setDialogCard(card);
  };

  const handleClose = () => {
    setOpen(false);
    console.log('handleClose');
    setChoice(null);
    setIsPriceLoaded(false);
    setPrice(0);
    setIsHealPriceLoaded(false);
    setHealPrice(0);
  };

  const handleCloseAnimation = () => {
    setOpenEvolveAnimation(false);
  };

  const handleCloseHeal = () => {
    setOpenHeal(false);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleEvolve = () => {
    setLoadingButton(true);
    axios({
      method: 'GET',
      url: process.env.REACT_APP_API_URL + '/card/evolve/' + dialogCard.data.id,
      headers: UserService.axiosToken()
    })
      .then((response) => {
        setPokemonsPokedexId(response.data.message.pokemonsPokedexId);
        setOpenEvolveAnimation(true);
        setLoadingButton(false);
        handleClose();
        getAllUserCards();
        setSnackbarMessage(response.data.message[0]);
        setSnackbarSeverity('success');
        window.dispatchEvent(new Event('updateCoins'));
      })
      .catch((error) => {
        setSnackbarMessage(error.response.data.message || 'Something went wrong, please try again later');
        setSnackbarSeverity('error');
      });

    setOpenSnackbar(true);
  };

  const handleSell = () => {
    let postData = {
      poke_id: dialogCard.data.id,
      market: choice === 'option1' ? 'market' : choice === 'option2' ? 'p2p' : null
    };

    if (choice === 'option2') {
      postData.price = parseFloat(price);
    }

    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_URL + '/marketplace/sell',
      headers: UserService.axiosToken(),
      data: postData
    })
      .then((response) => {
        handleClose();
        getAllUserCards();
        setSnackbarMessage(response.data.message);
        setSnackbarSeverity('success');
        window.dispatchEvent(new Event('updateCoins'));
      })
      .catch((error) => {
        setSnackbarMessage(error.response.data.message || 'Something went wrong, please try again later');
        setSnackbarSeverity('error');
      });

    setOpenSnackbar(true);
    setPrice('');
    console.log('handleSell');
    setChoice(null);
  };

  const handlePriceChange = () => {
    axios({
      method: 'GET',
      url: process.env.REACT_APP_API_URL + '/card/evolve/price/' + dialogCard?.data.id,
      headers: UserService.axiosToken()
    })
      .then((response) => {
        setEvolvePrice(response.data.message);
        setIsPriceLoaded(true);
      })
      .catch((error) => {
        setSnackbarMessage(error.response.data.message || 'Something went wrong, please try again later');
        setSnackbarSeverity('error');
      });
  };

  const handleHealPriceChange = () => {
    setIsHealPriceLoaded(false);
    axios({
      method: 'GET',
      url: process.env.REACT_APP_API_URL + '/card/heal/price/' + dialogCard.data.id,
      headers: UserService.axiosToken()
    })
      .then((response) => {
        setHealPrice(response.data.message);
        setIsHealPriceLoaded(true);
      })
      .catch((error) => {
        setSnackbarMessage(error.response.data.message || 'Something went wrong, please try again later');
        setSnackbarSeverity('error');
      });

    console.log('handleHealPriceChange');
    setChoice(null);
  };

  const handleHeal = () => {
    setLoadingHealingButton(true);
    axios({
      method: 'GET',
      url: process.env.REACT_APP_API_URL + '/card/heal/' + dialogCard.data.id,
      headers: UserService.axiosToken()
    })
      .then((response) => {
        handleClose();
        getAllUserCards();
        setLoadingHealingButton(false);
        setOpenHeal(true);
        setSnackbarMessage(response.data.message);
        setSnackbarSeverity('success');
        window.dispatchEvent(new Event('updateCoins'));
        setChoice(null);
      })
      .catch((error) => {
        setSnackbarMessage(error.response.data.message || 'Something went wrong, please try again later');
        setSnackbarSeverity('error');
      });

    setOpenSnackbar(true);
    setPrice('');
  };

  useEffect(() => {
    if (user) {
      setUserAvatar(user.avatar);

      getAllUserCards();
    }
  }, [user]);

  const getAllUserCards = () => {
    axios({
      method: 'GET',
      url: process.env.REACT_APP_API_URL + '/user/pokemons',
      headers: UserService.axiosToken()
    })
      .then((response) => {
        console.log(response.data.message);
        setUserCards(response.data.message);

        const cards = response.data.message;

        let profValue = 0; // Initialisez profValue à zéro

        cards.map((card) => {
          if (typeof card?.data?.cardmarket?.prices?.lowPrice == 'number') {
            profValue = profValue + card?.data?.cardmarket?.prices?.lowPrice;
          }
          return profValue;
        });

        setProfValue(Math.floor(profValue));

        let marketValue = 0; // Initialisez marketValue à zéro

        cards.map((card) => {
          if (typeof card?.data?.cardmarket?.prices?.trendPrice == 'number') {
            marketValue = marketValue + card?.data?.cardmarket?.prices?.trendPrice;
          }
          return marketValue;
        });
        // arrondir sans chiffre après la virgule
        setMarketValue(Math.floor(marketValue));

        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  let scrollLevel = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

  const handleNameChange = (e) => {
    setFilterName(e.target.value);
  };

  const filteredUserCardsCards = userCards
    .filter((card) => !(filterName && card?.data?.name.toLowerCase().indexOf(filterName.toLowerCase()) === -1))
    .sort((a, b) => {
      let aKey;
      let bKey;

      if (a.data.nationalPokedexNumbers === undefined) {
        aKey = a.data.name;
      } else if (b.data.nationalPokedexNumbers === undefined) {
        bKey = b.data.name;
      } else {
        aKey = a.data.nationalPokedexNumbers[0];
        bKey = b.data.nationalPokedexNumbers[0];
      }

      if (filterType === 'national-asc') {
        return aKey - bKey;
      } else if (filterType === 'national-desc') {
        return bKey - aKey;
      }

      return 0;
    })
    .sort((a, b) => {
      if (filterType === 'price-asc') {
        return (a?.data?.cardmarket?.prices?.trendPrice || 0) - (b?.data?.cardmarket?.prices?.trendPrice || 0);
      } else if (filterType === 'price-desc') {
        return (b?.data?.cardmarket?.prices?.trendPrice || 0) - (a?.data?.cardmarket?.prices?.trendPrice || 0);
      }

      return 0;
    });

  function handleClickOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  function handleChangeFilter(event) {
    setFilterType(event.target.value);
    // Close
    setOpenFilter(false);
  }

  console.log('choice : ', choice);

  return (
    <Stack direction="row" sx={{ width: '100%', height: '100%' }}>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert
          onClose={handleCloseSnackbar}
          color={snackbarSeverity}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Stack
        sx={{
          width: '60%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'sticky',
          top: 100
        }}
      >
        <Stack>
          <Avatar alt="User 1" src={userAvatar} sx={{ width: 250, height: 250 }} />
          <Typography variant="h4" sx={{ marginTop: 4 }}>
            {user.name}
          </Typography>

          <Card sx={{ marginTop: 4 }}>
            <CardHeader title="Statistique">
              <Typography variant="h4">Statistic</Typography>
            </CardHeader>
            <CardContent>
              <Typography variant="h6">Pokemon Number : {userCards.length}</Typography>
              <Typography variant="h6">Professor Value : {profValue}</Typography>
              <Typography variant="h6">Market Value : {marketValue}</Typography>
            </CardContent>
          </Card>
          <OutlinedInput
            type="text"
            placeholder="Search by name"
            value={filterName}
            onChange={handleNameChange}
            sx={{ backgroundColor: 'white', marginTop: 4 }}
          />
        </Stack>

        <Button
          variant="contained"
          sx={{
            mt: 4
          }}
          onClick={handleClickOpenFilter}
        >
          Filters
        </Button>

        <Dialog open={openFilter} onClose={handleCloseFilter} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Apply your filter</DialogTitle>
          <DialogContent>
            <DialogContentText>Choose your filters</DialogContentText>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <FormControl component="fieldset" sx={{ m: 3, alignItems: 'center' }}>
                <FormLabel component="legend">By Pokedex Number</FormLabel>
                <RadioGroup aria-label="National" name="national" value={filterType} onChange={handleChangeFilter}>
                  <FormControlLabel value="national-asc" control={<Radio />} label="Ascendant" />
                  <FormControlLabel value="national-desc" control={<Radio />} label="Descendant" />
                </RadioGroup>
              </FormControl>
              <FormControl component="fieldset" sx={{ m: 3, mb: 0, alignItems: 'center' }}>
                <FormLabel component="legend">By Price</FormLabel>
                <RadioGroup aria-label="Price" name="price" value={filterType} onChange={handleChangeFilter}>
                  <FormControlLabel value="price-asc" control={<Radio />} label="Ascendant" />
                  <FormControlLabel value="price-desc" control={<Radio />} label="Descendant" />
                </RadioGroup>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button onClick={handleCloseFilter}>Cancel</Button>
            <Button onClick={handleCloseFilter}>Ok</Button>
          </DialogActions>
        </Dialog>
      </Stack>

      <Stack sx={{ width: '100%', height: '100%' }}>
        {isLoading ? (
          <Box sx={{ position: 'absolute', top: '10%', right: '35%' }}>
            <Loading />
          </Box>
        ) : userCards.length !== 0 ? (
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 16 }}>
              {filteredUserCardsCards?.map((card) => {
                return (
                  <Grid item xs={5} key={card.id}>
                    <Button onClick={() => handleClickOpen(card)}>
                      <img src={card?.data.images.small} alt="img" style={{ width: '240px', height: '325px' }} />
                    </Button>
                    {card.quantity > 1 ? (
                      <Typography variant="body1">Quantity : {card.quantity}</Typography>
                    ) : (
                      <Typography variant="body1">&nbsp;</Typography>
                    )}
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        ) : (
          <Typography variant="h4" sx={{ marginTop: 5 }}>
            You don't have any pokemon
          </Typography>
        )}
      </Stack>
      <Dialog
        open={openEvolveAnimation}
        onClose={handleCloseAnimation}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          position: 'absolute',
          top: scrollLevel,
          height: '100%',
          '& .MuiModal-backdrop': {
            position: 'absolute'
          },
          '& .MuiPaper-root': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%'
          }
        }}
      >
        <DialogContent sx={{ width: '100%', backgroundColor: 'black', p: 0 }}>
          <Evolve pokemonsPokedexId={pokemonsPokedexId} />
        </DialogContent>
      </Dialog>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        sx={{
          position: 'absolute',
          top: scrollLevel,
          height: '100%'
        }}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {dialogCard?.data.name}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <Grid container sx={{ height: '100vh' }}>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <Card>
              <CardContent>
                <Typography variant="h2" sx={{ fontWeight: 'bold', margin: '.3em' }}>
                  {dialogCard?.data.name}
                </Typography>
                <img src={dialogCard?.data.images.small} alt="img" />
                <Typography variant="h4" sx={{ fontWeight: 'bold', margin: '.1em' }}>
                  Card Stats
                </Typography>
                <List>
                  <ListItem>
                    {/* Print hp / hp */}
                    <ListItemText primary="HP" secondary={`${dialogCard?.remainingHP} / ${dialogCard?.data.hp}`} />
                    <ListItemText
                      primary="Types"
                      secondary={dialogCard?.data.types?.map((type) => {
                        return <Chip label={type} />;
                      })}
                    />
                  </ListItem>
                  <Typography variant="h4" sx={{ fontWeight: 'bold', margin: '.1em' }}>
                    Attacks
                  </Typography>
                  <ListItem>
                    {dialogCard?.data.attacks?.map((attack) => {
                      return <ListItemText primary={attack.name} secondary={attack.damage} />;
                    })}
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <Card>
              <CardContent>
                <Typography variant="h2" sx={{ fontWeight: 'bold', margin: '1em' }}>
                  Manage your card
                </Typography>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  sx={{ justifyContent: 'center', margin: '1em' }}
                >
                  {dialogCard?.data?.evolvesTo && !dialogCard.data?.subtypes.includes('TAG TEAM') && (
                    <FormControlLabel
                      value="option3"
                      onClick={handlePriceChange}
                      control={<Radio />}
                      label="Evolve now"
                      onChange={(e) => setChoice(e.target.value)}
                    />
                  )}
                  {dialogCard?.remainingHP < dialogCard?.data?.hp && (
                    <FormControlLabel
                      value="option4"
                      onClick={handleHealPriceChange}
                      control={<Radio />}
                      label="Heal now"
                      onChange={(e) => setChoice(e.target.value)}
                    />
                  )}
                  <FormControlLabel
                    value="option1"
                    control={<Radio />}
                    label="Sell now"
                    onChange={(e) => setChoice(e.target.value)}
                  />
                  <FormControlLabel
                    value="option2"
                    control={<Radio />}
                    label="Sell at the marketplace"
                    onChange={(e) => setChoice(e.target.value)}
                  />
                </RadioGroup>
                {choice === 'option1' && (
                  <>
                    <Typography variant="h5" sx={{ margin: '1em' }}>
                      The professor will buy your card for <b>{dialogCard?.data?.cardmarket?.prices?.lowPrice} </b>
                      <Box component={'img'} src={Pokecoin} sx={{ width: '50px', transform: 'translateY(30%)' }} />
                    </Typography>
                    <Button onClick={handleSell} variant="contained">
                      Sell
                    </Button>
                  </>
                )}
                {choice === 'option2' && (
                  <>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <InputLabel htmlFor="standard-adornment-amount">Amount</InputLabel>
                      <Input
                        id="standard-adornment-amount"
                        startAdornment={<InputAdornment position="start">PC</InputAdornment>}
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                      />
                      <Typography variant="sm" sx={{ margin: '.5em 0 1em 0' }}>
                        Trending price: {dialogCard?.data?.cardmarket?.prices?.trendPrice}
                        <Box component={'img'} src={Pokecoin} sx={{ width: '30px', transform: 'translateY(30%)' }} />
                      </Typography>

                      <Button onClick={handleSell} variant="contained">
                        Sell
                      </Button>
                    </Box>
                  </>
                )}
                {choice === 'option3' &&
                  (isPriceLoaded ? (
                    <>
                      <Typography variant="h5" sx={{ margin: '1em' }}>
                        <b>This card will evolve for {evolvePrice} </b>
                        <Box component={'img'} src={Pokecoin} sx={{ width: '50px', transform: 'translateY(30%)' }} />
                      </Typography>
                      {loadingButton ? (
                        <LoadingButton loading variant="outlined">
                          Submit
                        </LoadingButton>
                      ) : (
                        <Button onClick={handleEvolve} variant="contained">
                          Evolve
                        </Button>
                      )}
                    </>
                  ) : (
                    <CircularProgress />
                  ))}
                {choice === 'option4' &&
                  (isHealPriceLoaded ? (
                    <>
                      <Typography variant="h5" sx={{ margin: '1em' }}>
                        <b>This card will be healed for {healPrice} </b>
                        <Box component={'img'} src={Pokecoin} sx={{ width: '50px', transform: 'translateY(30%)' }} />
                      </Typography>
                      {loadingHealingButton ? (
                        <LoadingButton loading variant="outlined">
                          Submit
                        </LoadingButton>
                      ) : (
                        <Button onClick={handleHeal} variant="contained">
                          Heal
                        </Button>
                      )}
                    </>
                  ) : (
                    <CircularProgress />
                  ))}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Dialog>

      {/*  Dialog to say that your pokemon has been healed */}
      <Dialog
        open={openHeal}
        onClose={handleCloseHeal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          position: 'absolute',
          top: scrollLevel,
          height: '100%',
          '& .MuiModal-backdrop': {
            position: 'absolute'
          },
          '& .MuiPaper-root': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%'
          }
        }}
      >
        <DialogTitle id="alert-dialog-title">{'Your pokemon has been healed'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your pokemon has been healed, you can now use it in battle
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseHeal} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}

export default Profile;
