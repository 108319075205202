import { Box, Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import UserService from '../../services/UserService';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Pack() {
  const [packs, setPacks] = useState([]);
  const navigate = useNavigate();

  if (!UserService.isLoggedIn()) {
    window.location.href = '/login';
  }

  useEffect(() => {
    getAllPacks();
  }, []);

  const getAllPacks = () => {
    axios({
      method: 'GET',
      url: process.env.REACT_APP_API_URL + '/user/packs',
      headers: UserService.axiosToken()
    })
      .then((response) => {
        setPacks(response.data.message);

        if (response.data.message.length === 0) {
          navigate('/shop');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Box sx={{ mt: 5, mb: 5 }}>
      <Grid container spacing={{ xs: 2, md: 3 }} rowSpacing={{ md: 10 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        {packs?.map((pack, index) => (
          <Grid item key={index} xs={2} sm={4} md={4}>
            <Box
              sx={{
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                  scale: '1.03'
                }
              }}
            >
              <Box
                component="img"
                src={pack.image}
                sx={{
                  display: 'flex',
                  m: 'auto',
                  height: { xs: '150px', md: '400px' },
                  width: { xs: '150px', md: 'auto' },
                  boxShadow: 'rgba(255, 255, 255, 1) 0px 3px 13px'
                }}
              />
              <Typography variant="h4" sx={{ fontSize: 24, mt: 2, mb: 2 }}>
                {pack.quantity > 1 ? `x${pack.quantity}` : ''}
              </Typography>
              <Button
                variant="outlined"
                color="inherit"
                onClick={() => {
                  navigate('/openPack/' + pack.id);
                }}
                sx={{ marginTop: pack.quantity === 1 && 6 }}
              >
                Open {pack.name}
              </Button>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default Pack;
