import { Box, Button, Grid, Skeleton, Typography } from '@mui/material';
import React from 'react';

function ShopSkeleton({ key }) {
  return (
    <Grid item xs={2} sm={4} md={4} key={key}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          m: 'auto',
          height: { xs: '150px', md: '500px' },
          width: { xs: '150px', md: 'auto' }
        }}
      >
        <Skeleton variant="rectangular" width={221} height={400} animation="wave" />
        <Skeleton variant="text" width={210} height={50} animation="wave" />
        <Skeleton variant="rectangular" width={210} height={35} animation="wave" />
      </Box>
    </Grid>
  );
}

export default ShopSkeleton;
