import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import UserService from '../../services/UserService';
import './OpenPack.css';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';

function OpenPack() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [pack, setPack] = useState();
  const [cards, setCards] = useState();
  const [openPackButton, setOpenPackButton] = useState(true);
  const [redirectionButton, setRedirectionButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [text, setText] = useState('Flip All Cards');
  const [anotherPackText, setAnotherPackText] = useState('Flip All Cards');
  const [openAnotherPack, setOpenAnotherPack] = useState(false);
  const [flippedCardsCount, setFlippedCardsCount] = useState(0);

  if (!UserService.isLoggedIn()) {
    window.location.href = '/login';
  }

  const getPackById = async (id) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_URL + '/pack/' + id,
      headers: UserService.axiosToken()
    })
      .then((response) => {
        setPack(response.data.message);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };

  const getPackOpen = async (id) => {
    setOpenPackButton(false);
    setIsLoading(true);

    axios({
      method: 'get',
      url: process.env.REACT_APP_API_URL + '/pack/open/' + id,
      headers: UserService.axiosToken()
    })
      .then((response) => {
        setCards(response.data.message);
        setIsLoading(false);
        const packOpened = document.getElementById('pack-opened');
        packOpened.classList.toggle('open');
      })
      .catch((error) => {
        console.log(error.response.data.message);
        if (error.response.data.message === "You don't have this pack") {
          navigate('/myPacks');
        }
      });
  };

  useEffect(() => {
    getPackById(id);
  }, []);

  const handleCardFlip = (event) => {
    const element = event.currentTarget;

    if (element.className === 'carta') {
      if (element.style.transform === 'rotateY(180deg)') {
        element.style.transform = 'rotateY(0deg)';
        setFlippedCardsCount(flippedCardsCount - 1);
      } else {
        element.style.transform = 'rotateY(180deg)';
        setFlippedCardsCount(flippedCardsCount + 1);
      }
      if (flippedCardsCount === 4) {
        setText('Go to My Packs');
        setOpenAnotherPack(true);
        setAnotherPackText(`Open Another Pack ${pack?.name} (${pack?.quantity - 1} left)`);
      } else {
        setText('Flip All Cards');
        setOpenAnotherPack(false);
      }
    }
    setRedirectionButton(true);
  };

  const flip = () => {
    const cartaList = document.getElementsByClassName('carta');
    let flippedCount = 0;
    for (let carta of cartaList) {
      if (carta.style.transform === 'rotateY(180deg)') {
        flippedCount++;
      }
    }
    if (flippedCount === cartaList.length) {
      setText('Go to My Packs');
      window.location.replace('/myPacks');
    } else {
      for (let carta of cartaList) {
        if (carta.style.transform !== 'rotateY(180deg)') {
          carta.style.transform = 'rotateY(180deg)';
          setText('Go to My Packs');
        }
      }
    }
  };

  return (
    <Box sx={{ pt: 3 }}>
      <Button
        variant="outlined"
        color="inherit"
        onClick={() => {
          getPackOpen(id);
        }}
        sx={{ display: !openPackButton && 'none' }}
      >
        Open This Pack {pack?.name}
      </Button>
      <Box id="pack-opened" class="col-xs-12">
        <Box class="pack-content" sx={{ display: 'block', visibility: 'visible' }}>
          <Box class="pack-flash">
            <Box class="pack-flash-pack">
              <img class="front" src={pack?.image} alt="" />
              <Box class="top">
                <img src="https://i.imgur.com/b1qmOW6.png" alt="" />
                <Box class="cut">
                  <img src="https://i.imgur.com/k55nnYY.png" alt="" />
                </Box>
                <span>
                  <img src="https://i.imgur.com/JqedAsJ.png" alt="" />
                  <span>
                    <img src="https://i.imgur.com/WWRXjri.png" alt="" />
                    <span>
                      <img src="https://i.imgur.com/DzEYvSP.png" style={{ width: '81px' }} alt="" />
                    </span>
                  </span>
                </span>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '20%',
              justifyContent: 'center',
              alignItems: 'center',
              margin: 'auto'
            }}
          >
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => flip()}
              sx={{ transform: 'translateX(0) translateY(400px)', p: 3, display: !redirectionButton && 'none' }}
            >
              {text}
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              onClick={() =>
                !openAnotherPack || pack?.quantity <= 1
                  ? flip() // Reload the page
                  : window.location.reload()
              }
              sx={{
                transform: 'translateX(0) translateY(400px)',
                p: 3,
                marginTop: 10,
                maxWidth: 300,
                display: !openAnotherPack || pack?.quantity <= 1 ? 'none' : 'block'
              }}
            >
              {anotherPackText}
            </Button>
          </Box>
          <Box class="cards-reveal">
            <ul>
              {cards?.map((card, index) => (
                <li class={`card-0${index}`}>
                  <Box class="carta" onClick={handleCardFlip}>
                    <Box class="frente">
                      <img
                        src={card?.data?.images?.large}
                        style={{ boxShadow: !card?.alreadyHave && 'rgb(255 199 0) 0px 6px 50px' }}
                        alt=""
                      />
                      {card?.newCard}
                    </Box>
                    <Box class="tras">
                      <img src="https://images.pokemoncard.io/images/assets/CardBack.jpg" alt="" />
                    </Box>
                  </Box>
                </li>
              ))}
            </ul>
          </Box>
        </Box>
        {isLoading && <Loading sx={{ display: isLoading ? 'block' : 'none' }} />}
      </Box>
    </Box>
  );
}

export default OpenPack;
