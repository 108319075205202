const LocalStorageService = {
  getStorage: function(key) {
    let value = sessionStorage.getItem(key) || localStorage.getItem(key) || null;
    return value && typeof value === 'string' && value[0] === '{' ? JSON.parse(value) : value;
  },

  setStorage: function(key, value, inSession = false) {
    value = (typeof value === 'object') ? JSON.stringify(value) : value;

    if (inSession) {
      sessionStorage.setItem(key, value);
    } else {
      localStorage.setItem(key, value);
    }
  },

  removeStorage: function(key) {
    if (this.isSessionStorage(key)) {
      sessionStorage.removeItem(key);
    } else {
      localStorage.removeItem(key);
    }
  },

  updateStorage: function(key, value) {
    let oldValue = this.getStorage(key);
    if (oldValue) {
      if (typeof oldValue === 'object') {
        value = Object.assign(oldValue, value);
      } else {
        value = oldValue + value;
      }
    }

    this.setStorage(key, value, this.isSessionStorage(key));
  },

  clearStorage: function() {
    sessionStorage.clear();
    localStorage.clear();
  },

  getStorageKeys: function() {
    return Object.keys(sessionStorage).concat(Object.keys(localStorage));
  },

  getStorageValues: function() {
    return Object.values(sessionStorage).concat(Object.values(localStorage));
  },

  isSessionStorage: function(key) {
    return sessionStorage.getItem(key) !== null;
  }
};

export default LocalStorageService;
