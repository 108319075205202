import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  DialogContent,
  Alert,
  Snackbar
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import UserService from '../../services/UserService';
import ShopSkeleton from '../../components/skeleton/ShopSkeleton';

function Shop() {
  const [allPacks, setAllPacks] = useState([]);

  const [openDialog, setOpenDialog] = React.useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [openValideSnackbar, setOpenValideSnackbar] = React.useState(false);

  const [packId, setPackId] = useState();
  const [quantity, setQuantity] = useState(1);

  const [error, setError] = useState('');
  const [valide, setValide] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  if (!UserService.isLoggedIn()) {
    window.location.href = '/login';
  }

  const handleClickOpenDialog = (id) => {
    getPackById(id);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseErrorSnackbar = () => {
    setOpenErrorSnackbar(false);
  };

  const handleCloseValideSnackbar = () => {
    setOpenValideSnackbar(false);
  };

  useEffect(() => {
    getAllPacks();
  }, []);

  const getPackById = async (id) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_URL + '/pack/' + id,
      headers: UserService.axiosToken()
    })
      .then((response) => {
        setPackId(response.data.message);
        setOpenDialog(true);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };

  const getAllPacks = async (id) => {
    let packs = [];
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_URL + '/packs',
      headers: UserService.axiosToken()
    })
      .then((response) => {
        packs = response.data.message;
        setAllPacks(packs);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };

  const buyPack = async (id, quantity) => {
    if (quantity === '' || isNaN(quantity)) {
      quantity = 0;
    }
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_URL + '/pack/buy/' + id + '/' + quantity,
      headers: UserService.axiosToken()
    })
      .then((response) => {
        setValide(response.data.message);
        setOpenDialog(false);
        setOpenValideSnackbar(true);
        setQuantity(1);
        window.dispatchEvent(new Event('updateCoins'));
      })
      .catch((error) => {
        console.log(error);
        setError(error.response.data.message);
        setOpenDialog(false);
        setOpenErrorSnackbar(true);
      });
  };

  let niveauDeScroll = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

  return (
    <Stack
      sx={{
        background:
          'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,29,61,1) 20%, rgba(0,23,49,1) 80%, rgba(0,0,0,1) 100%)'
        // 'gray'
      }}
    >
      <Box sx={{ mt: 5, mb: 5 }}>
        <Grid container spacing={{ xs: 2, md: 3 }} rowSpacing={{ md: 10 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          {!isLoading
            ? allPacks?.map((pack, index) => (
                <Grid item key={index} xs={2} sm={4} md={4}>
                  <Box
                    sx={{
                      transition: 'all 0.3s ease-in-out',
                      '&:hover': {
                        scale: '1.03'
                      }
                    }}
                  >
                    <Box
                      component="img"
                      src={pack.image}
                      sx={{
                        display: 'flex',
                        m: 'auto',
                        height: { xs: '150px', md: '400px' },
                        width: { xs: '150px', md: 'auto' },
                        boxShadow: 'rgba(255, 255, 255, 1) 0px 3px 13px',
                        filter: !pack.buyable ? 'grayscale(100%)' : 'none'
                      }}
                    />
                    <Typography variant="h4" sx={{ fontSize: 24, mt: 2, mb: 2 }}>
                      {pack.name}
                    </Typography>
                    <Button
                      variant="outlined"
                      color="inherit"
                      onClick={() => {
                        handleClickOpenDialog(pack.id);
                      }}
                    >
                      Buy for {pack.price}{' '}
                      <Box
                        component="img"
                        src="https://cdn.discordapp.com/attachments/1089824824962076722/1090991848560013432/pokecoin.png"
                        alt="pokecoins"
                        sx={{
                          width: '20px',
                          height: '20px',
                          marginLeft: '5px'
                        }}
                      />
                    </Button>
                  </Box>
                </Grid>
              ))
            : //   map on 6 item and show skeleton
              Array(6)
                .fill()
                .map((_, index) => <ShopSkeleton key={index} />)}
        </Grid>
      </Box>

      <Snackbar
        sx={{
          top: 'auto',
          position: 'absolute',
          '& .MuiSnackbar-anchorOriginBottomLeft': {
            position: 'absolute'
          },
          bottom: 2,
          left: 0
        }}
        open={openErrorSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseErrorSnackbar}
      >
        <Alert onClose={handleCloseErrorSnackbar} color="error" severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>

      <Snackbar
        sx={{
          top: 'auto',
          position: 'absolute',
          '& .MuiSnackbar-anchorOriginBottomLeft': {
            position: 'absolute'
          },
          bottom: 2,
          left: 0
        }}
        open={openValideSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseValideSnackbar}
      >
        <Alert onClose={handleCloseValideSnackbar} color="success" severity="success" sx={{ width: '100%' }}>
          {valide}
        </Alert>
      </Snackbar>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          position: 'absolute',
          top: niveauDeScroll,
          height: '100%',
          '& .MuiModal-backdrop': {
            position: 'absolute'
          },
          '& .MuiPaper-root': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }
        }}
      >
        <DialogTitle id="alert-dialog-title" sx={{ display: 'flex' }}>
          Are you sure to buy this pack for {quantity * packId?.price}{' '}
          <Box
            component="img"
            src="https://cdn.discordapp.com/attachments/1089824824962076722/1090991848560013432/pokecoin.png"
            alt="pokecoins"
            sx={{
              width: '30px',
              height: 'auto'
            }}
          />{' '}
          ?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box
              component="img"
              src={packId?.image}
              sx={{
                display: 'flex',
                m: 'auto',
                height: { xs: '150px', md: '400px' },
                width: { xs: '150px', md: 'auto' }
              }}
            />
          </DialogContentText>
        </DialogContent>
        <Box
          component={'input'}
          id="outlined-number"
          label="Quantity"
          type="number"
          sx={{
            width: '80%',
            marginY: '1rem',
            padding: '0.5rem',
            borderRadius: '5px',
            border: '1px solid #ccc',
            outline: 'none',
            fontSize: '1.2rem',
            '&:focus': {
              border: '1px solid #4d90fe',
              boxShadow: '0 0 0 2px rgba(77,144,254,.2)'
            }
          }}
          InputLabelProps={{
            shrink: true
          }}
          variant="outlined"
          value={quantity}
          onChange={(e) => {
            setQuantity(e.target.value);
          }}
        />
        <DialogActions>
          <Button variant="outlined" color="error" onClick={handleCloseDialog}>
            Cancel
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              buyPack(packId?.id, quantity);
            }}
            autoFocus
          >
            Buy
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}

export default Shop;
