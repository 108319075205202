import React from 'react';
import './Home.css';

import { Box, Typography, Button } from '@mui/material';

import Pokeball from '../../assets/images/pokeball.png';

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import InventoryIcon from '@mui/icons-material/Inventory';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export default function Home() {
  return (
    <Box>
      <main id="app">
        {/* Slogan  */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            padding: { xs: '2rem 0', md: '5rem 0' },
            background: 'linear-gradient(0deg, #001d3d 0%, rgba(0, 0, 0, 1) 100%)'
          }}
        >
          <Box
            sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: { xs: '70%', md: '50%' } }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
                textAlign: 'initial',
                padding: { xs: '10px 10px 10px 20px', md: '2rem' }
              }}
            >
              <Typography
                variant="h3"
                sx={{ fontSize: { xs: '1rem', md: '4rem' }, fontWeight: 'bold', lineHeight: '1' }}
              >
                Collect all cards
              </Typography>
              <Typography
                variant="h3"
                sx={{ fontSize: { xs: '1rem', md: '4rem' }, fontWeight: 'bold', lineHeight: '1' }}
              >
                And make fights
              </Typography>
              <Typography
                variant="p"
                sx={{
                  fontSize: { xs: '10px', md: '20px' },
                  lineHeight: '1.5',
                  padding: { xs: '10px 0', md: '3rem 0' }
                }}
              >
                Buy and open booster to collect cards, evolve then and make you own deck to fight against famous
                trainers.
              </Typography>
              <Button
                variant="outlined"
                color="inherit"
                href="#howItWork"
                sx={{ color: 'white', fontSize: { xs: '10px', md: '2rem' } }}
              >
                <KeyboardArrowRightIcon sx={{ paddingRight: '10px', fontSize: { xs: '20px', md: '3rem' } }} /> see
                process
              </Button>
            </Box>
          </Box>
          <Box
            component="img"
            src={Pokeball}
            sx={{ display: 'flex', mr: 1, height: { xs: '150px', md: 'auto' }, width: { xs: '150px', md: '600px' } }}
          />
        </Box>

        {/* How does it work ? */}
        <Box sx={{ background: 'linear-gradient(0deg, #001d3d 0%, #001d3d 100%)' }}>
          <Typography
            variant="h4"
            sx={{
              fontSize: { xs: '2rem ', md: '3rem' },
              fontWeight: 'bold',
              lineHeight: '1.5',
              textAlign: 'center'
            }}
            id="howItWork"
          >
            How does it work ?
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              alignItems: 'center',
              padding: { xs: '2rem 0', md: '4rem 0' }
            }}
          >
            <Box
              component="img"
              src="https://cdn.discordapp.com/attachments/930039778332786718/1090366018070773840/firePack.png"
              sx={{ display: 'flex', mr: { xs: 0, md: 1 }, height: { xs: '150px', md: '400px' } }}
            />
            <Box
              component="img"
              src="https://cdn.discordapp.com/attachments/930039778332786718/1090366602123427960/waterPack.jpeg"
              sx={{ display: { xs: 'none', md: 'flex' }, mr: 1, height: { xs: '150px', md: '400px' } }}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: { xs: '60%', md: '50%' }
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  textAlign: 'initial',
                  padding: { xs: '10px', md: '2rem' }
                }}
              >
                <Typography
                  variant="h3"
                  sx={{ fontSize: { xs: '1rem', md: '3rem' }, fontWeight: 'bold', lineHeight: '1.5' }}
                >
                  Buy and open booster
                </Typography>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: { xs: '10px', md: '20px' },
                    lineHeight: '1.5',
                    padding: { xs: '10px 0', md: '2rem 0' }
                  }}
                >
                  You can buy booster in the shop, open it and collect cards. You have several booster to choose sort by
                  type. See your booster in the section "MyPacks".
                </Typography>
                <Button
                  variant="outlined"
                  color="inherit"
                  href={'/shop'}
                  sx={{ color: 'white', fontSize: { xs: '10px', md: '2rem' } }}
                >
                  <ShoppingCartIcon sx={{ paddingRight: '10px', fontSize: { xs: '20px', md: '3rem' } }} /> Buy booster
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Exemple of cards */}
        <Box
          sx={{
            padding: { xs: '1rem 0', md: '4rem 0' },
            display: { xs: 'none', md: 'block' },
            background: 'linear-gradient(0deg, #001d3d 0%, #001d3d 100%)'
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontSize: { xs: '2rem ', md: '3rem' },
              fontWeight: 'bold',
              lineHeight: '1.5',
              textAlign: 'center',
              paddingBottom: { xs: '1rem', md: '2rem' }
            }}
          >
            Exemple of cards
          </Typography>

          <Box className="cards">
            <Box className="card charizard animated"></Box>
            <Box className="card pika animated"></Box>
            <Box className="card eevee animated"></Box>
            <Box className="card mewtwo animated"></Box>
          </Box>
        </Box>

        {/* Make fights */}

        <Box
          sx={{
            padding: { xs: '1rem 0', md: '4rem 0' },
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, #001d3d 100%)'
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontSize: { xs: '2rem ', md: '3rem' },
              fontWeight: 'bold',
              lineHeight: '1.5',
              textAlign: 'center'
            }}
          >
            Make fights
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              padding: { xs: '2rem 0', md: '4rem 0' }
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: { xs: '60%', md: '50%' }
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  textAlign: 'initial',
                  padding: { xs: '10px 10px 10px 20px', md: '2rem' }
                }}
              >
                <Typography
                  variant="h3"
                  sx={{ fontSize: { xs: '1rem', md: '3rem' }, fontWeight: 'bold', lineHeight: '1.5' }}
                >
                  Prepare your team
                </Typography>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: { xs: '10px', md: '20px' },
                    lineHeight: '1.5',
                    padding: { xs: '10px 0', md: '2rem 0' }
                  }}
                >
                  With the cards you have collected, you can make your own deck before the battle. You can make evolve
                  your cards to make them more powerful.
                </Typography>
                <Button
                  variant="outlined"
                  color="inherit"
                  href={'/profile'}
                  sx={{ color: 'white', fontSize: { xs: '10px', md: '2rem' } }}
                >
                  <InventoryIcon sx={{ paddingRight: '10px', fontSize: { xs: '20px', md: '3rem' } }} /> See my cards
                </Button>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Box
                component="img"
                src="https://cdn.discordapp.com/attachments/930039778332786718/1090371726694678738/image.png"
                sx={{ display: 'flex', mr: { xs: 0, md: 1 }, height: { xs: '150px', md: '400px' } }}
              />
              <Box
                component="img"
                src="https://cdn.discordapp.com/attachments/930039778332786718/1090371980961796326/vs-fire-icon-png-logo-Image-10.png"
                sx={{ display: 'flex', mr: { xs: 0, md: 1 }, height: { xs: '30px', md: '200px' } }}
              />
              <Box
                component="img"
                src="https://cdn.discordapp.com/attachments/930039778332786718/1090371681480089640/image.png"
                sx={{ display: 'flex', mr: 1, height: { xs: '150px', md: '400px' } }}
              />
            </Box>
          </Box>
        </Box>
        <style className="hover"></style>
      </main>
    </Box>
  );
}
