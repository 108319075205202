import LocalStorageService from './LocalStorageService';
import axios from 'axios';

const UserService = {
  isLoggedIn: function () {
    return !!LocalStorageService.getStorage('token');
  },

  logAUser: async function (response, rememberMe = true) {
    await LocalStorageService.setStorage('token', response['token'], !rememberMe);
    await LocalStorageService.setStorage('user', response['user'], !rememberMe);

    this.sendEvent();
  },

  sendEvent: function () {
    window.dispatchEvent(new Event('storage'));
  },

  getUserFromStorage: function () {
    if (!this.isLoggedIn()) {
      return null;
    }

    return LocalStorageService.getStorage('user');
  },

  updateCoins: async function () {
    const url = `${process.env.REACT_APP_API_URL}/user/coins`;
    const headers = this.axiosToken();

    try {
      const response = await axios.get(url, { headers });
      return response.data.message['coins'];
    } catch (error) {
      console.error(error);
    }
  },

  axiosToken: function (extraHeaders = {}) {
    return {
      Authorization: 'Bearer ' + LocalStorageService.getStorage('token'),
      ...extraHeaders
    };
  },

  logout: function () {
    LocalStorageService.removeStorage('token');
    LocalStorageService.removeStorage('user');
  }
};

export default UserService;
