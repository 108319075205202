import { Route, Routes } from 'react-router-dom';
import Home from './components/Home/Home';
import Error from './pages/error/Error';
import SignUp from './components/Registration/Register';
import Catalog from './pages/catalog/Catalog';
import SignIn from './components/Registration/Login';
import ForgotPassword from './components/Registration/ForgotPassword';
import ResetPassword from './components/Registration/ResetPassword';
import Shop from './pages/shop/Shop';
import Profile from './pages/profile/Profile';
import Pack from './pages/pack/Pack';
import OpenPack from './pages/openPack/OpenPack';
import Battle from './pages/battle/Battle';
import Marketplace from './pages/marketplace/Marketplace';

function RoutesProvider() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="*" element={<Error />} />
      <Route path="/register" element={<SignUp />} />
      <Route path="/catalog" element={<Catalog />} />
      <Route path="/login" element={<SignIn />} />
      <Route path="/forgot_password" element={<ForgotPassword />} />
      <Route path="/reset_password/:token" element={<ResetPassword />} />
      <Route path="/shop" element={<Shop />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/myPacks" element={<Pack />} />
      <Route path="/openPack/:id" element={<OpenPack />} />
      <Route path="/battle" element={<Battle />} />
      <Route path="/marketplace" element={<Marketplace />} />
      {/*<Route path="about" element={<Header />} />*/}
      {/*<Route path="dashboard" element={<Dashboard />} />*/}
    </Routes>
  );
}

export default RoutesProvider;
