import { Alert, Box, FormControl, InputLabel, MenuItem, Select, Snackbar, Stack, Typography } from '@mui/material';
import axios from 'axios';
import UserService from '../../services/UserService';
import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import Fight from './components/Fight';

function Battle() {
  const [user, setUser] = useState(UserService.getUserFromStorage());
  const [userCards, setUserCards] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [chosenPokemon, setChosenPokemon] = useState(null);
  const [isBattle, setIsBattle] = useState(false);
  const [battleData, setBattleData] = useState(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [snackbarSeverity, setSnackbarSeverity] = React.useState('success');


  const navigate = useNavigate();

  if (!UserService.isLoggedIn()) {
    window.location.href = '/login';
  }

  useEffect(() => {
    if (user) {
      console.log('UserService.axiosToken()', UserService.axiosToken());
      getAllUserCards();
    }
  }, [user]);
  console.log('userCards', userCards);

  const getAllUserCards = () => {
    axios({
      method: 'GET',
      url: process.env.REACT_APP_API_URL + '/user/pokemons',
      headers: UserService.axiosToken()
    })
      .then((response) => {
        console.log(response.data);
        setUserCards(response.data.message);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChange = (event) => {
    setChosenPokemon(event.target.value);
    console.log('event.target.value', event.target.value);
  };

  const handleBattle = () => {
    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_URL + '/battle/create',
      headers: UserService.axiosToken(),
      data: {
        pokemon: chosenPokemon
      }
    })
      .then((response) => {
        console.log(response.data);
        // setUserCards(response.data.message);
        // setIsLoading(false);
        setBattleData(response.data.message);
        setIsBattle(true);
      })
      .catch((error) => {
        console.log(error);
        setSnackbarMessage(error.response.data.message);
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
      });
  };

  console.log('isBattle', isBattle);

  function handleCloseSnackbar() {
    setOpenSnackbar(false);
  }

  // console.log('chosenPokemon', chosenPokemon);
  return (
    <>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert
          onClose={handleCloseSnackbar}
          color={snackbarSeverity}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {!isBattle ? (
        <Stack
          sx={{
            width: '100vw',
            height: '100vh',
            background:
              'linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 29, 61, 1) 10%, rgba(0, 23, 49, 1) 80%, rgba(0, 0, 0, 1) 100%)'
          }}
        >
          <Stack sx={{ width: 100 }}>
            <Button variant="outlined" sx={{ margin: 3 }} onClick={() => navigate('/')}>
              Back
            </Button>
          </Stack>

          {isLoading ? (
            <Typography sx={{}}>Loading...</Typography>
          ) : userCards.length !== 0 ? (
            <>
              <Stack direction="row" sx={{ display: 'flex', justifyContent: 'space-around' }}>
                <Stack>
                  {chosenPokemon ? (
                    userCards
                      ?.filter((card) => card?.data?.id === chosenPokemon)
                      ?.map((card) => {
                        return (
                          <Box
                            component="img"
                            src={card?.data?.images?.large}
                            alt="Pokemon"
                            sx={{
                              width: 450,
                              height: 650
                            }}
                          />
                        );
                      })
                  ) : (
                    // https://images.pokemoncard.io/images/assets/CardBack.jpg
                    // https://images.pokemontcg.io/mcd15/8_hires.png
                    <Box
                      component="img"
                      src="https://images.pokemoncard.io/images/assets/CardBack.jpg"
                      alt="Pokemon"
                      sx={{
                        width: 450,
                        height: 650,
                        borderRadius: 8
                      }}
                    />
                  )}
                </Stack>

                <Box sx={{ minWidth: 120, marginTop: 5 }}>
                  <FormControl
                    sx={{
                      width: 250,
                      '& .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon': {
                        color: 'white'
                      }
                    }}
                  >
                    <InputLabel id="demo-simple-select-label" sx={{ color: 'white' }}>
                      Chosen Pokemon
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={'' || chosenPokemon}
                      label="Chosen Pokemon"
                      onChange={handleChange}
                      sx={{
                        color: 'white',
                        borderColor: 'white',
                        '&:hover': {
                          borderColor: 'white',
                          '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white'
                          }
                        },
                        '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                          borderColor: 'white'
                        }
                      }}
                    >
                      {userCards
                        ?.filter(
                          (card) =>
                            !card?.data?.subtypes?.includes('Supporter') &&
                            !card?.data?.subtypes?.includes('Special') &&
                            card.data.nationalPokedexNumbers < 649
                        )
                        ?.map((card) => {
                          return (
                            <MenuItem value={card?.data?.id} key={card?.data?.id}>
                              <Box
                                component="img"
                                src={`https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/versions/generation-vii/icons/${card?.data?.nationalPokedexNumbers}.png`}
                                alt="img"
                                sx={{
                                  width: 40,
                                  height: 30
                                }}
                              />
                              {card?.data?.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>

                  <Button variant="outlined" sx={{ marginLeft: 10 }} onClick={() => handleBattle()}>
                    Start Battle
                  </Button>
                </Box>
              </Stack>
            </>
          ) : (
            <Typography sx={{}}>You dont have pokemon yet !</Typography>
          )}
        </Stack>
      ) : (
        <Fight
          userCards={userCards}
          chosenPokemon={chosenPokemon}
          setIsBattle={setIsBattle}
          setChosenPokemon={setChosenPokemon}
          battleData={battleData}
        />
      )}
    </>
  );
}

export default Battle;
